import { post } from "./api"
import { state } from "venti"

export const defaultMessage = {
  role: 'assistant',
  content: `Hi, I'm Chrome Autopilot and I can perform actions in your web browser. How can I help?`,
  timestamp: new Date().toLocaleTimeString(),
}

const MESSAGES_URI = '/v1/messages'

export async function getMessages() {
  return fetch(MESSAGES_URI)
    .then(res => res.json())
    .then(({ messages }) => {
      state.set('messages', messages)
    })
}

function appendMessage(message) {
  const firstMessage = state.get('messages.0')
  if (!firstMessage) {
    state.set('messages', [defaultMessage])
  }
  state.update('messages', messages => [...messages, message], [])
}

function replaceLastMessage(message) {
  state.update('messages', messages => messages.slice(0, -1))
  appendMessage(message)
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export async function sendMessage(user, content, extensionInstalled) {
  appendMessage({ role: 'user', content })
  if (!user) {
    appendMessage({ role: 'assistant', content: '', showTyping: true })
    await sleep(1000)
    replaceLastMessage({ role: 'assistant', content: 'Please sign in to begin' })
    return
  }
  if (!extensionInstalled) {
    appendMessage({ role: 'assistant', content: '', showTyping: true })
    await sleep(1000)
    replaceLastMessage({ role: 'assistant', content: 'Please install the Chrome Extension', showInstallExtension: true })
    return
  }
  appendMessage({ role: 'assistant', content: '', showTyping: true })
  console.log('sending message', content)
  const response = await post(MESSAGES_URI, { content })
  console.log('response', response)
  if (response.content) {
    const showCountdown = !!response.script
    replaceLastMessage({ ...response, showCountdown })
  }
  // execute script
  if (response.script) {
    await sleep(3000)
    const script = `let run = window.runAction;\n(async () => {\n${response.script}\n})()`
    console.log(script)
    const done = await eval(script).catch(console.log)
    // appendMessage({ role: 'assistant', content: 'Done!' })
  }
  
}
