import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Launch from './components/Launch';
import Assistant from './components/Assistant'
import Assistants from './components/Assistants'
import Agents from './components/Actions'
import { GoogleOAuthProvider } from '@react-oauth/google';
import NotFound from './components/NotFound';
import Chat from './components/Chat';
import Jobs from 'components/Jobs';
import GetStarted from 'components/GetStarted';
import Home from 'components/Home';

export default function App() {
  return (
    <>
      <GoogleOAuthProvider clientId="419610695706-5kpuku8d5ckutikj0odvj5svlgrl4c9u.apps.googleusercontent.com">
        <Router>
          <Routes>
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/" element={<Home />} />
            <Route path="/chat" element={<Assistant />} />
            <Route path="/chat/:id" element={<Assistant />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </>
  );
}
