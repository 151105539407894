import { useState, useEffect } from 'react';

export function useChromeAutopilotSDK() {
    const [module, setModule] = useState({});
    useEffect(() => {
      async function fetchModule() {
        try {
          setModule(await import(
            /* webpackIgnore: true */
            window.location.origin+'/sdk-1.0.0.js'
          ));
        } catch (err) {
          console.log('Error importing chrome autopilot sdk', err)
        }
      }
      fetchModule();
    });
    return module;
  }
  