import { post } from "./api"
import { state, useVenti } from "venti"
import useAuth from "./useAuth"
import { useAction } from "./actions"

function formatMessage(content) {
  if (!content) return
  return {
    role: 'assistant',
    content,
    timestamp: new Date().toLocaleTimeString(),
  }
}

const MESSAGES_URI = '/v1/messages'

function getKey(assistantId) {
  return assistantId ? `assistantMessages.${assistantId}` : 'messages'
}

export async function getMessages(assistantId, defaultMessage) {
  const key = getKey(assistantId)
  const url = assistantId ? `${MESSAGES_URI}?assistantId=${assistantId}` : MESSAGES_URI
  return fetch(url)
    .then(res => res.json())
    .then(({ messages = [] }) => {
      messages = [defaultMessage, ...messages].filter(Boolean)
      // if (!messages.length && defaultMessage) messages = [defaultMessage]
      state.set(key, messages)
    })
}

export function useAssistantMessages(assistantId) {
  const state = useVenti()
  const user = useAuth()
  const action = useAction(assistantId)
  if (!action && !action?.loading) return []
  const key = getKey(assistantId)
  const messages = state.get(key, [])
  const defaultMessage = formatMessage(action.inputPrompt)
  if (!messages.length && user) getMessages(assistantId, defaultMessage)
  return messages
}

export function appendMessage(assistantId, message) {
  const key = getKey(assistantId)
  const firstMessage = state.get(`${key}.0`)
  if (!firstMessage) {
    // state.set(key, [defaultMessage])
  }
  state.update(key, messages => [...messages, message], [])
}

function replaceLastMessage(assistantId, message) {
  const key = getKey(assistantId)
  state.update(key, messages => messages.slice(0, -1))
  appendMessage(assistantId, message)
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export async function sendMessage(user, content, extensionInstalled, assistantId) {
  appendMessage(assistantId, { role: 'user', content })
  if (!user) {
    appendMessage(assistantId, { role: 'assistant', content: '', showTyping: true })
    await sleep(1000)
    replaceLastMessage(assistantId, { role: 'assistant', content: 'Please sign in to begin' })
    return
  }
  if (!extensionInstalled) {
    console.log('extension not installed')
    appendMessage(assistantId, { role: 'assistant', content: '', showTyping: true })
    await sleep(1000)
    replaceLastMessage(assistantId, { 
      role: 'assistant', 
      content: 'Please install Chrome Autopilot from the Chrome Web Store. On the next page, click "Add to Chrome".', 
      showInstallExtension: true 
    })
    return
  }
  appendMessage(assistantId, { role: 'assistant', content: '', showTyping: true })
  // console.log('sending message', content)
  const response = await post(MESSAGES_URI, { content, assistantId })
  // console.log('response', response)
  if (response.content) {
    const showCountdown = !!response.script
    replaceLastMessage(assistantId, { ...response, showCountdown })
  }
  // execute script
  if (response.script) {
    await sleep(3000)
    const script = `let run = window.runAction;\n(async () => {\n${response.script}\n})()`
    // console.log(script)
    const done = await eval(script).catch(console.log)
    // appendMessage({ role: 'assistant', content: 'Done!' })
  }
  
}
