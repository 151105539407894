import React from 'react';
import { chromeWebstoreURL, useExtensionInstalled } from '../../services/useExtension.js';
import { useChromeAutopilotSDK } from '../../services/useChromeAutopilotSDK.js';
import styles from './styles.module.scss';
import Footer from '../Footer/index.js';
import Header from '../Header/index.js';
import { useActions } from 'services/actions.js';

const Actions = ({ assistantsOnly = false }) => {
  const extensionInstalled = useExtensionInstalled();
  const { importAndRunAction } = useChromeAutopilotSDK();
  const actions = useActions({ assistantsOnly });

  const getExtensionInNewTab = () => {
    window.open(chromeWebstoreURL, '_blank');
  }

  const getOnClick = ({ id }) => {
    if (!extensionInstalled) {
      return () => getExtensionInNewTab()
    }
    return () => window.location.href = `/chat/${id}`
    // const action = actions.find(m => m.id === id)
    // if (!action) return () => alert('Action not found')
    // if (action.isComingSoon) {
    //   return () => alert('Coming soon!')
    // }
    // return () => importAndRunAction(id, null, { origin: window.location.origin })
  }

  return (
    <>
      <Header />
      <section className={styles.tasks}>
        <section className={styles.tasksGrid}>
          {actions.map(({ 
              name, 
              description, 
              banner,
              domains,
              button,
              id,
              isComingSoon,
            }, i) => {
              const href = `/chat/${id}`
              return (
                <div className={`${styles.task} ${isComingSoon ? styles.inactive : null}`} key={i}>
                  {(banner || domains) && <div className={styles.priceBanner}>{banner || domains}</div>}
                  <div className={styles.taskInfo}>
                    <h3><a href={href}>{name}</a></h3>
                    <div>{description}</div>
                  </div>
                  <div className={styles.beginButton}>
                    <button onClick={getOnClick({ id })}>{button || 'Begin'}</button>
                  </div>
                </div>
              )
            }
          )}
        </section>
      </section>
      <Footer />
    </>
  );
}

export default Actions;
