/* global chrome */
import { useEffect, useState } from "react";
import { getExtensionInfo } from '../sdk-1.0.0.js'

export const extensionId = 'idahijhccencfhigphpmlnjbppldolgk';
const src = 'img/logo-128.png';
export const chromeWebstoreURL = 'https://chromewebstore.google.com/detail/idahijhccencfhigphpmlnjbppldolgk'

window.ChromeAutopilot = window.ChromeAutopilot || { on: {} }

const isExtensionInstalled = () => {
  return new Promise((resolve, reject) => {
    let e = new Image()
    e.src = `chrome-extension://${extensionId}/${src}`
    e.onload = () => resolve(1)
    e.onerror = () => reject(0)
  })
}

export { getExtensionInfo }

export function useExtensionVersion() {
  const extensionInfo = useExtensionInfo();
  if (!extensionInfo) return null;
  const { version } = extensionInfo;
  return version;
}

export function useExtensionInfo() {
  const [extensionInfo, setExtensionInfo] = useState(null);
  useEffect(() => {
    async function fetchExtensionInfo() {
      try {
        const info = await getExtensionInfo(extensionId);
        setExtensionInfo(info);
      } catch (error) {
        console.error('Failed to fetch extension info:', error);
      }
    }
    fetchExtensionInfo();
  }, []);
  return extensionInfo;
}

export function useIsSidePanelOpen() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  useEffect(() => {
    window.ChromeAutopilot.on.isSidePanelOpen = (isOpen) => {
      setIsSidePanelOpen(isOpen)
    }
  }, []);
  return isSidePanelOpen;
}

export function useExtensionInstalled() {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  useEffect(() => {
    async function checkExtensionInstalled() {
      const isInstalled = await isExtensionInstalled();
      setExtensionInstalled(isInstalled);
    }
    checkExtensionInstalled().catch();
  }, []);
  return extensionInstalled;
}

export function isCompatible(installedVersion, requiredVersion) {
  const v1 = installedVersion || '0.0.0'
  const v2 = requiredVersion || '0.0.0'
  const [major1, minor1, patch1] = v1.split('.').map(Number);
  const [major2, minor2, patch2] = v2.split('.').map(Number);
  if (major1 > major2) return true;
  if (major1 < major2) return false;
  if (minor1 > minor2) return true;
  if (minor1 < minor2) return false;
  if (patch1 > patch2) return true;
  if (patch1 < patch2) return false;
  return true; // versions are equal
}
