import { useEffect, useState, useRef } from "react";

export default function H1() {
    // every second change the value of taskName
  const taskNames = ['navigate', 'send', 'scrape', 'reply', 'submit', 'post', 'click', 'copy', 'type', 'draft', 'summarize', 'find', 'highlight','sign in', 'schedule', 'locate', 'trade', 'buy', 'sell', 'register', 'publish', 'extract', 'respond', 'translate', 'unsubscribe', 'pay', 'request']
  const maxTaskNameLength = Math.max(...taskNames.map(name => name.length))
  const [taskName, setTaskName] = useState(taskNames[0])
  const taskIndexRef = useRef(0)
  const pad = ''.padEnd(maxTaskNameLength - taskName.length, '\u00A0')

  useEffect(() => {
    const interval = setInterval(() => {
      setTaskName(taskNames[taskIndexRef.current])
      taskIndexRef.current = (taskIndexRef.current + 1) % taskNames.length
    }, 750)
    return () => clearInterval(interval)
  }, [])

  return  (
    <h1 className="heroText">
      <span>The AI assistant that can <span className="taskName">{taskName}.{pad}</span></span>
    </h1>
  )
}