import { useState, useEffect } from 'react'
import { useVenti, state } from 'venti'

export async function importAction(id ) {
  if (!id) throw new Error('Action `id` is required')
  const origin = window.location.origin
  const isLocalhost = origin.includes('localhost')
  const modulePath = `https://chromeautopilot.com/actions/${id}.js`
  if (!isLocalhost) {
    return import(/* webpackIgnore: true */ modulePath)
  }
  // console.log(`${origin}/macros/${id}.js?${Date.now()}`)
  return import(/* webpackIgnore: true */ `${origin}/actions/${id}.js?${Date.now()}`)
    // .catch(err => {
    //   console.log(err)
    //   if (!err.message.includes('fetch')) throw err
    //   return import(/* webpackIgnore: true */ modulePath).catch()
    // })
}

export function useAction(id) {
  const [action, setAction] = useState(null)
  useEffect(() => {
    async function fetchAndSetAction() {
      if (id) {
        const fetchedAction = await importAction(id)
        setAction(fetchedAction)
      } else {
        setAction({ isAssistant: true })
      }
    }
    fetchAndSetAction()
  }, [id]) // Re-run the effect when `id` changes
  return action
}

export async function getActions() {
  // Instead of fetching from server, just set our default agents
  const agents = [{
    id: 'SmartScrape',
    name: 'Smart scrape',
    description: 'Intelligently scrape and extract data from any webpage',
    banner: 'AI-powered',
    rank: 1
  }]
  state.set('actions', agents)
  return { actions: agents }
}

export function useActions({ assistantsOnly = false }) {
  const state = useVenti()
  const actions = state.get('actions', [])
  if (!actions.length) {
    const agents = [{
      id: 'SmartScrape',
      name: 'Smart scrape',
      description: 'Intelligently scrape and extract data from any webpage',
      banner: 'AI-powered',
      rank: 1
    }]
    state.set('actions', agents)
  }
  return actions
}