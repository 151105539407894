import Footer from "components/Footer";
import Header from "components/Header";
import './styles.css';
import useAuth, { useForceReloadAfterLogin, openGoogleLogin } from "services/useAuth";
import { chromeWebstoreURL, useExtensionInstalled, useIsSidePanelOpen } from "services/useExtension";
import H1 from "./H1";

export default function Home() {
  const user = useAuth()
  
  // TODO: check extension version
  const extensionInstalled = useExtensionInstalled()
  useForceReloadAfterLogin()
  
  const isSidePanelOpen = useIsSidePanelOpen()
  const showOpenSidePanelButton = !isSidePanelOpen && user && extensionInstalled

  if (showOpenSidePanelButton) {
    console.log('window.ChromeAutopilot', window.ChromeAutopilot)
    if (window.ChromeAutopilot?.sendToContentScript) {
      console.log('sendToContentScript showOpenSidePanelButton')
      window.ChromeAutopilot.sendToContentScript('showOpenSidePanelButton')
    } else {
      console.log('sendToContentScript not found')
    }
  }
  
  return (
    <>
      <Header />

      <div className="content">
        <section className="hero">
          <H1 />
          <div className="subline">
            Chat with any webpage and watch it complete 
            the assignment.
          </div>
        </section>

        {!user && (
          <section className="heroButton">
            <div className="signInGoogle" onClick={openGoogleLogin}></div>
            {/* <div>No credit card needed -- try for free!</div> */}
          </section>
        )}
        {user && !extensionInstalled && (
          <section className="heroButton">
            <div className="get-button">
              <a href={chromeWebstoreURL}>Get Chrome Autopilot →</a>
            </div>
            <div>On the next page, click 'Add to Chrome' to install the Chrome Extension</div>
          </section>
        )}
        { showOpenSidePanelButton && (
          <section className="heroButton">
            <div className="get-button" id="openSidePanelContainer"></div>
          </section>
        )}
        {isSidePanelOpen && user && extensionInstalled && (!user.plan || user.plan === 'free') && (
          <section className="heroButton">
            <div className="get-button">
              <a href='/checkout'>✨ Upgrade to Premium</a>
            </div>
            <div>Subscribe for $20/month</div>
          </section>
        )}

        <section className="bg">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/gvK5vidb1qk?loop=1&playlist=gvK5vidb1qk&rel=0"
              title="Chrome Autopilot Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>
          
      </div>
      <Footer />
    </>
  )
}

