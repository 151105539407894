import useSWR from 'swr'

export const get = url => fetch(url).then(r => r.json())

export function useAPI(uri) {
  const { data, isLoading } = useSWR(uri, get)
  return { data, isLoading }
}

export async function post(uri, body) {
  // console.log('posting', uri, body)
  const res = await fetch(uri, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
  const data = await res.json()
  // console.log('response', data)
  return data
}