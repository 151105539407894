import React, { useEffect, useState } from 'react'
import s from './styles.module.scss';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import useAuth from '../../services/useAuth';
import Typing from './Typing';
import { chromeWebstoreURL } from 'services/useExtension';

function timeAgo(input) {
  const date = (input instanceof Date) ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat('en');
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (let key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(Math.round(delta), key);
    }
  }
}

function installChromeExtension() {
  window.open(chromeWebstoreURL)
}

export default function Message({
  role, 
  content, 
  timestamp, 
  showCountdown = false,
  showTyping = false,
  showInstallExtension = false,
  actionName,
}) {
  const user = useAuth()
  const isUserMessage = role === 'user'
  const messageContainer = role === 'user' ? s.messageContainerUser : s.messageContainerAssistant
  const avatar = role === 'user' ? s.avatarUser : s.avatarAssistant
  const assistantName = actionName && <a className={s.assistantName} href={`/chat/${actionName}`}>{actionName}</a>
  return (
    <div className={messageContainer}>
      <div className={s.message}>
        <div className={avatar}
          style={{
            backgroundImage: isUserMessage ? `url(${user?.picture})` : null
          }}
        >
        </div>
        <div className={s.content}>
          <div className={s.nameLabel}>{isUserMessage ? 'You' : assistantName || 'Chrome Autopilot'}</div>
          <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
          {showCountdown && <Countdown />}
          {showTyping && <Typing />}
          {showInstallExtension && (
            <button onClick={installChromeExtension} className={s.installExtensionButton}>Get Chrome Autopilot →</button>
          )}
        </div>
        <div className={s.timestamp}>{timeAgo(timestamp)}</div>
      </div>
    </div>
  )
}
  
function Countdown({ start = 3 }) {
  const [count, setCount] = useState(start);
  useEffect(() => {
    if (count > 0) {
      const timerId = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [count]);
  return !!count && <div>Launching in {count}</div>;
}