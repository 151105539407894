import React, { useEffect, useRef } from 'react'
import './Assistant.css'
import Message from '../Message'
import useAuth, { showGoogleOneTapLogin, useForceReloadAfterLogin } from '../../services/useAuth'
import Header from '../Header'
import { useExtensionInstalled, chromeWebstoreURL } from 'services/useExtension'
import { useParams } from 'react-router-dom'
import { getMessages, sendMessage, useAssistantMessages, appendMessage } from 'services/assistantMessages'
import { useAction } from 'services/actions'

function usePostMessageListener(actionId) {
  useEffect(() => {
    const onWatchEventHandler = (event) => {
      if (event.origin !== window.location.origin) return
      if (event.data.chromeautopilot && event.data.request) {
        if (event.data.request.command === 'sendMessageToUser') {
          console.log('message being sent to user', event.data.request.params)
          setTimeout(() => {
            getMessages(actionId)
          }, 500)
        }
      }
    }
    window.addEventListener('message', onWatchEventHandler)
    return () => window.removeEventListener('message', onWatchEventHandler)
  }, [actionId])
}

function Messages({ messages = [] }) {
  const { id } = useParams();
  const action = useAction(id)
  return messages.map((message, i) => {
    const isLastMessage = i === messages.length - 1
    if (message.showCountdown && !isLastMessage) {
      message.showCountdown = false
    }
    // const assistantName = action?.isAssistant ? action?.name : 'AI Assistant'
    return <Message key={i} {...message} />
  })
}

function AssistantHeader() {
  const { id } = useParams();
  const action = useAction(id)
  if (!action) return null
  return <>
    <div className='actionHeader'>
      <div className='actionHeaderInner'>
        <span className="actionName">{action.name}</span>
        <span className="betaPill">Beta</span>
        <span className="assistantMenuIcon">▼</span>
      </div>
    </div>
  </>
}

function ActionHeader() {
  const { id } = useParams();
  const action = useAction(id)
  if (!action) return null
  return <>
    <div className='actionHeader'>
      <div className='actionHeaderInner'>
        <h1 className="actionName">
          <span>{action.name}</span>
          <span className="betaPill">Beta</span>
        </h1>
        <div className="actionDescription">{action.description}</div>
      </div>
    </div>
  </>
}

export default function Assistant() { 
  const user = useAuth()
  const { id: actionId } = useParams()
  const action = useAction(actionId)
  const actionAssistant = useAction('ActionAssistant')
  const extensionInstalled = useExtensionInstalled()
  const messages = useAssistantMessages(actionId)
  if (messages.length === 0) {
    if (action?.inputPrompt) appendMessage(actionId, { role: 'assistant', content: action.inputPrompt })
    else if (action?.name) appendMessage(actionId, { role: 'assistant', content: `Say "go" to get started!` })
    // else appendMessage(actionId, { role: 'assistant', content: 'What can I help you with?' })
  }
  useForceReloadAfterLogin()
  usePostMessageListener(actionId)

  const scrollRef = useRef(null)

  // Scroll to bottom when scrollRef becomes available
  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    });
    observer.observe(document, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [])

  // scroll to the bottom of the chat window when new messages are added
  useEffect(() => {
    const scrollContainer = scrollRef.current
    if (scrollContainer) {
      const observer = new MutationObserver(() => {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
        document.querySelector('textarea').focus()
      });
      observer.observe(scrollContainer, { childList: true });
      return () => {
        observer.disconnect();
      };
    }
  }, [])

  if (!action) return (<div>Loading...</div>)
  const { default: runAction } = action

  async function submit() {
    const content = document.querySelector('textarea').value
    if (!content) return
    if (!user) {
      showGoogleOneTapLogin()
      return appendMessage(actionId, { 
        role: 'assistant', 
        content: 'Please sign in to begin.' 
      })
    }
    document.querySelector('.text-input textarea').value = ''
    sendMessage(user, content, extensionInstalled, actionId)
    if (!action.isAssistant) {
      return actionAssistant.default([...messages, { role: 'user', content }], action)
    }
    return runAction([...messages, { role: 'user', content }])
  } 

  return (
    <div className="container">
      <div className="main">
        <Header />
        <div className="main-content" ref={scrollRef}>
          {action.isAssistant ? null : <ActionHeader />}
          <Messages messages={messages} />
        </div>
        <div className="main-footer">
          <div className="text-input">
            <textarea 
              type="text" 
              placeholder="Type a message" 
              autoFocus 
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  submit()
                }
              }}
            />
            <div className="send-button" onClick={submit}></div>
          </div>
        </div>
      </div>
    </div>
  )
}
